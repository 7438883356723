<template>
<div class='resourcesContainer'>

    <h2 class='title' style='margin-left:5px; margin-bottom:16px; color:#c45d4a;'>Documents</h2>
    <div v-if="documents.length>0 || links.length>0" :class="{closed: !seeMoreDocumentCards, closed_documentCards: !seeMoreDocumentCards, documentCardsContainer: true}">
        <template v-for="(document, index) in documents">
            <DocumentCard v-if="isAllowed(document)" :key="`document-${index}`" :resource="document" class='documentCard'/>
            <!-- End loop, then use watch to execute code -->
            <span style='display:none;' v-if="index == documents.length -1 ? loaded_documents=true : '' "></span>
        </template>
        <template v-for="(document, index) in links">
            <DocumentCard v-if="isAllowed(document)" :key="`link-${index}`" :resource="document" class='documentCard'/>
            <!-- End loop, then use watch to execute code -->
            <span style='display:none;' v-if="index == links.length -1 ? loaded_links=true : '' "></span>
        </template>
    </div>
    <p v-else-if="documents.length===0">No published documents</p>            
    <v-btn v-if="documentCardsBelowOverflow || (!documentCardsBelowOverflow && seeMoreDocumentCards)" class='seeMore' @click="seeMore('document')" style='text-transform:initial;'>
        See
        <span v-if="documentCardsBelowOverflow"> More</span>
        <span v-else-if="seeMoreDocumentCards"> Less</span>
    </v-btn>

    <h2 class='title' style='margin-left:5px; margin-top:16px; margin-bottom:16px;'>Videos</h2>
    <div v-if="videos.length>0" :class="{closed: !seeMoreVideos, autoHeight_videosContainer: seeMoreVideos, videosContainer: true}">
        <template  v-for="(video, index) in videos">
            <VideoCard v-if="isAllowed(video)" class='videoCard' :key="`video-${index}`" :resource="video"/>
            <!-- End loop, then use watch to execute code -->
            <span style='display:none;' v-if="index == videos.length - 1 ? loaded_videos=true : '' "></span>
        </template>
    </div>
    <p v-else-if="videos.length===0">No published media</p>               
    <v-btn v-if="videosBelowOverflow || (!videosBelowOverflow && seeMoreVideos)" class='seeMore' @click="seeMore('video')" style='text-transform:initial;'>
        See
        <span v-if="videosBelowOverflow"> More</span>
        <span v-else-if="seeMoreVideos"> Less</span>
    </v-btn>

</div>
</template>

<script>
import DocumentCard from './documentCard'
import VideoCard from './videoCard'
import PostMeetingResources from '@/mixins/postMeetingResources.js'
export default {
    name: "ResourcesCmpnt",
    props: {
        eventId: {
            type: Number,
            required: false
        },
        definition: {
            type: Array,
            required: true
        },
        context_key: {
            type: String,
            required: false,
            default: null
        }
    },
    components: {
        DocumentCard,
        VideoCard
    },
    data: function(){
        return {
            documentCardsBelowOverflow: false,
            videosBelowOverflow: false,
            seeMoreDocumentCards: false,
            seeMoreVideos: false,
            loaded_documents: false,
            loaded_links: false,
            loaded_videos: false,
            setup_documentsContainer: false,
            videoTypes: ['mp4']
        }
    },
    methods: {
        isAllowed: function(resource){
            let eventId = this.eventId ? this.eventId : resource.eventId ? resource.eventId : undefined
            let username = this.$store.getters.user.username
            let meeting = eventId ? this.meetingMap[eventId] : undefined
            let restrictions = resource.restrictedTo
            let myRoles = []

            if(meeting){
                for(let key in meeting.roles){
                    let users = meeting.roles[key]
                    if(users.filter((item)=>{return item==username}).length>0){
                        myRoles.push(key)
                    }
                }
            }

            let allow = restrictions.length==0
            if(restrictions.length>0){
                for(let i=0; i<restrictions.length; i++){
                    let role = restrictions[i]
                    if(myRoles.includes(role)){
                        allow = true
                    }
                }
            }

            return allow
        },
        isDocumentCardsBelowOverflow: function(){
            let documentCardsContainer = document.querySelector(`.documentCardsContainer`);
            let documentCardsContainer_bottom = documentCardsContainer.getBoundingClientRect().bottom;

            let documentCards = document.querySelectorAll(`.documentCard`);
            for(let i = 0; i < documentCards.length; i++){
                let item = documentCards[i];
                let item_bottom = item.getBoundingClientRect().bottom;

                if( item_bottom > documentCardsContainer_bottom){
                    this.documentCardsBelowOverflow = true;
                    return;
                };

                if( i === documentCards.length - 1){ // end of loop
                    this.documentCardsBelowOverflow = false;
                };
            };
        },
        isVideosBelowOverflow: function(){
            let videosContainer = document.querySelector(`.videosContainer`);
            let videosContainer_bottom = videosContainer.getBoundingClientRect().bottom;

            let videoCards = document.querySelectorAll('.videoCard');
            for(let i = 0; i < videoCards.length; i++){
                let item = videoCards[i];
                let item_bottom = item.getBoundingClientRect().bottom;

                if( item_bottom > videosContainer_bottom){
                    this.videosBelowOverflow = true;
                    return;
                };

                if( i === videoCards.length - 1){ // end of loop
                    this.videosBelowOverflow = false;
                };
            };

        },
        giveVideosContainerConstrainedHeight: function(){
            // get videosContainer. Need to set video container height to tallest video card in 1st visible row
            let videosContainer = document.querySelector(`.videosContainer`);
            // get all videoCards to work with
            let videoCards = document.querySelectorAll(`.videoCard`);
            // get the top of the first videoCard
            let topOfFirstVideoCard = videoCards[0].getBoundingClientRect().top;
            // where to collect only the videoCards in the top row
            let videosInTopRow = [];
            // loop to extract target height
            for(let i = 0; i < videoCards.length; i++){
                let videoCard = videoCards[i];
                // collect only the videoCards in the top row
                if( videoCard.getBoundingClientRect().top == topOfFirstVideoCard){
                    videosInTopRow.push(videoCard);
                }
                // when no longer true, loop videos on top row to find the one with the tallest height
                else{
                    let tallestHeight = 0;
                    for(let ii = 0; ii < videosInTopRow.length; ii++){
                        let videoInTopRow = videosInTopRow[ii];
                        let heightOfVideoInTopRow = videoInTopRow.getBoundingClientRect().height;
                        if( heightOfVideoInTopRow > tallestHeight){
                            tallestHeight = heightOfVideoInTopRow; // by end of loop, tallest height will be found
                        };
                        // end of loop
                        if( ii === videosInTopRow.length - 1){
                            // set the videosContainer height to the height of the tallest videoCard in first row
                            videosContainer.style.height = `${tallestHeight + 10}px`; // add a bit more (10) for padding
                            return;
                        };
                    };
                };
            };
        },
        seeMore: function(type){

            if( type == 'document'){
                if( this.seeMoreDocumentCards == false){
                    this.seeMoreDocumentCards = true;
                    this.documentCardsBelowOverflow = false;
                }
                else {
                    this.seeMoreDocumentCards = false;
                    this.documentCardsBelowOverflow = true;
                };
            };

            if( type == 'video'){
                if( this.seeMoreVideos == false){
                    this.seeMoreVideos = true;
                    this.videosBelowOverflow = false;
                }
                else {
                    this.seeMoreVideos = false;
                    this.videosBelowOverflow = true;
                };
            };
        }
    },
    computed: {
        links: function(){
            if ((this.context_key==='post_meeting_materials')){
                return []
            }
            let resources = this.definition
            let output = resources.filter((item)=>{return item.link && item.link.url})
            return output
        },
        documents: function(){
            if ((this.context_key==='post_meeting_materials')){
                return this.isMeetingEnded ? PostMeetingResources.postMeetingDocuments(this.definition) : []
            }            
            let resources = this.definition
            let output = resources.filter((item)=>{return !item.link
                    && (item.category===undefined || item.category!=='post meeting')
                    && !this.videoTypes.includes(item.asset.Key.substr(item.asset.Key.lastIndexOf('.')+1,item.asset.Key.length))})
            return output
        },
        videos: function(){
            if ((this.context_key==='post_meeting_materials')){
                return this.isMeetingEnded ? PostMeetingResources.postMeetingRecordings(this.definition, this.videoTypes) : []
            }
            let resources = this.definition
            let output = resources.filter((item)=>{return !item.link
                    && (item.category===undefined || item.category!=='post meeting')
                    && this.videoTypes.includes(item.asset.Key.substr(item.asset.Key.lastIndexOf('.')+1,item.asset.Key.length))})
            return output
        },
        meetingMap: function(){
            let meetings = this.$store.getters.meetings ? this.$store.getters.meetings : []
            let output = {}
            for(let i=0; i<meetings.length; i++){
                let meeting = meetings[i]
                output[meeting.id] = meeting
            }

            return output
        },
        isMeetingEnded: function(){           
          let end = new Date(this.meetingMap[this.eventId].end)
          let now = new Date()
          return  (now-end) /1000/60/60/24 >= 0
        }       
    },
    watch: {
        documentCardsBelowOverflow: function(){
            this.$forceUpdate();
        },
        videosBelowOverflow: function(){
            this.$forceUpdate();
        },
        loaded_documents: function(){
            if( this.loaded_links==true || this.links.length==0){
                if( this.setup_documentsContainer == false){
                    this.setup_documentsContainer = true;
                    this.isDocumentCardsBelowOverflow();
                    let self = this;
                    function setupDocumentsContainer(){
                        self.isDocumentCardsBelowOverflow();
                        self.seeMoreDocumentCards = false;
                    };
                    window.addEventListener('resize', setupDocumentsContainer);
                };
            };
        },
        loaded_links: function(){
            if( this.loaded_documents==true || this.documents.length==0){
                if( this.setup_documentsContainer == false){
                    this.setup_documentsContainer = true;
                    this.isDocumentCardsBelowOverflow();
                    let self = this;
                    function setupDocumentsContainer(){
                        self.isDocumentCardsBelowOverflow();
                        self.seeMoreDocumentCards = false;
                    };
                    window.addEventListener('resize', setupDocumentsContainer);
                };
            }
        },
        loaded_videos: function(){
            this.giveVideosContainerConstrainedHeight();
            this.isVideosBelowOverflow();
            let self = this;
            function setupVideosContainer(){
                self.giveVideosContainerConstrainedHeight();
                self.isVideosBelowOverflow();
                self.seeMoreVideos = false;
            };
            window.addEventListener('resize', setupVideosContainer);
        }
    }
}
</script>

<style lang="scss" scoped>

.resourcesContainer {
    padding: 48px 12%;

    @media(max-width: 1199px){ // lg < 1200
        padding: 40px 10%;
    }
    @media(max-width: 991px){ // md < 992
        padding: 32px 8%;
    }
    @media(max-width: 767px){ // sm < 768
        padding: 32px 6%;
    }
    @media(max-width: 575px){ // xs < 576
        padding: 24px 4%;
    }

    .closed {
        position: relative;
        overflow: hidden;
    }
    .closed_documentCards {
        height: 116px;
    }

    .autoHeight_videosContainer {
        height: auto !important;
    }

    .seeMore {
        display: block;
        width: 180px;
        border-radius: 100px;
        margin: 16px auto 0;
        background-color: $orange;
        color: white;
    }

    .documentCard {
        display: inline-block;
        margin: 5px;

        @media(max-width: 575px){ // xs < 576
            width: calc(100% - 5px);
        }
    }

    .videosContainer {

        .videoCard {
            display: inline-block;
            margin: 5px;
        }
    }
}
</style>

<template>
<div>
    <div v-if="url" class="video-card">
        <video :id="'videoPlayer_'+resource.asset.ETag"
            disablePictureInPicture controls controlsList="nodownload"
            @play="playing" @resume="playing" @seeking="seeking" @pause="paused" @ended="ended">
            <source :src="url" type="video/mp4">
            Your browser does not support the video tag.
        </video>

        <div class="descriptionLabel">
            <img src="../../assets/CanClearLogo_leaf.png" alt="">
            <p class='resourceLabel'>{{resource.label}}</p>
        </div>

        <p class='resourceFaculty'>{{resource.faculty}}</p>
    </div>
</div>
</template>

<script>
export default {
    name: "VideoCard",
    props: {
        resource: {
            type: Object,
            required: true
        },
        activeTab: {
            type: Number
        }
    },
    data: function(){
        return {
            url: null
        }
    },
    created(){
        return this.getSignedUrl();
    },
    beforeDestroy: function(){
        window.removeEventListener('contextmenu', event => event.preventDefault());
    },
    computed: {
        player: function(){
            return document.getElementById('videoPlayer_' + this.resource.asset.ETag);
        }
    },
    methods: {
        getSignedUrl: async function(){
            let asset = this.resource.recording!==undefined ? this.resource.recording : this.resource.asset
            let response = await this.sendRequest('put','/api/assets/signedUrl', asset)
            if(response.status==200){
                return this.url = response.data.url
            }
            return;
        },
        playing: function(){
            let asset = this.resource.recording!==undefined ? this.resource.recording : this.resource.asset
            this.sendRequest('post','/api/meetings/assets/usage', {resource: asset.Key, user_id: this.$store.getters.user.username, action: 'video_play_click'})
            this.$emit('playing');
            /*
            let self = this;
            let options = this.options;
            if(!self.resumed && options && options.resume){
                self.player.currentTime = (self.player.duration - options.resume) > 3 ? options.resume : 0;
                self.resumed = true;
            }

            this.playing_interval.push( setInterval(function(){
                self.$emit('playing')
                if(self.player.paused){
                    clearTimeout(self.timer)
                }
            },5000));
            *
            */
        },
        ended: function(){
            this.$emit('ended');
            /*
            let self = this;
            for(let i=0; i<this.playing_interval.length; i++){
                window.clearInterval(this.playing_interval[i]);
                this.playing_interval[i] = null;
            }
            self.$emit('ended');
            window.clearTimeout(self.timer);
            setTimeout(function(){
                self.player.load();
            },800)
            *
            */
        },
        paused: function(){
            let self = this;
            self.$emit('pause')
        },
        seeking: function(){
            let self = this;
            self.$emit('seeking')
        }
    },
    watch: {
        url: function(after, before){
            let self = this
            if (after){
                setTimeout(function(){
                    let videoPlayer = document.getElementById('videoPlayer_' + self.resource.asset.ETag);
                    videoPlayer.addEventListener('contextmenu', event => event.preventDefault());
                    self.$emit('player',self.player);
                }, 1)
            }
        },
        activeTab: function(after, before){
            if (after!==2){
                this.player.pause();
            }
        }
    }
}
</script>

<style scoped lang="scss">

.video-card {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column;
    width: 240px;

    @media(max-width: 575px){ // xs < 576
        display: block;
        width: 100%;
        margin-top: -24px;
    }

    video {

        @media(max-width: 575px){ // xs < 576
            width: calc(100% + 5px);
            margin-top: 40px;
        }
    }

    .descriptionLabel {
        display: flex;
        width: 100%;
        height: 20px;
        margin-bottom: 2px;

        p {
            font-size: 11px;
            margin-top: 2px;
            font-weight: 500;
            color: $red_dark;
        }

        img {
            height: 100%;
            margin-right: 6px;
            margin-top: 8px;
        }

        .resourceLabel {
            font-size: 14px;
            margin-top: 8px;
            margin-bottom: 2px;
        }
    }

    .resourceFaculty {
        font-size: 12px;
        margin-left: 2px;
    }
}
</style>

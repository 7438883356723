<template>
    <div id='DocumentCard'>

        <template v-if="type=='chip' && documentType=='link'">
            <v-chip class='resourceChip' :href="resource.link.url" target="_blank" @click="processClickEvent">

                <template v-if="documentType=='pdf'">
                    <v-icon class='typeIcon' small left color="#c45d4a">
                        mdi-file-pdf-box
                    </v-icon>
                </template>
                <template v-else-if="documentType=='word'">
                    <v-icon class='typeIcon' small left color="blue">
                        mdi-file-document
                    </v-icon>
                </template>
                <template v-else-if="documentType=='image'">
                    <v-icon class='typeIcon' small left color="orange">
                        mdi-file-image
                    </v-icon>
                </template>
                <template v-else-if="documentType=='excel'">
                    <v-icon class='typeIcon' small left color="green">
                        mdi-file-chart
                    </v-icon>
                </template>
                <template v-else-if="documentType=='ppt'">
                    <v-icon class='typeIcon' small color="red">
                        mdi-file-powerpoint-outline
                    </v-icon>
                </template>
                <template v-else-if="documentType=='link'">
                    <v-icon class='typeIcon' small color="teal">
                        {{resource.link.icon}}
                    </v-icon>
                </template>
                <template v-else>
                    <v-icon class='typeIcon' small left>
                        mdi-file
                    </v-icon>
                </template>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <p class='resourceLabel' v-bind="attrs" v-on="on">{{resource.label}}</p>
                    </template>
                    <span>{{resource.label}}</span>
                </v-tooltip>

                <v-btn class='downloadOrLinkButton' v-if="documentType=='link'" style='margin: 8px;' icon>
                    <v-icon>mdi-link</v-icon>
                </v-btn>
                <v-btn v-else class='downloadOrLinkButton' style='margin: 8px;' color="#4C0E0C" icon>
                    <v-icon>mdi-download-circle</v-icon>
                </v-btn>
            </v-chip>
        </template>

        <template v-else-if="type=='chip' && documentType!='link'">
            <v-chip class='resourceChip' :href="url ? url : '/'"  @click.prevent="getSignedUrl" target="_blank">
                <template v-if="documentType=='pdf'">
                    <v-icon class='typeIcon' small left color="red">
                        mdi-file-pdf-box
                    </v-icon>
                    </template>
                <template v-else-if="documentType=='word'">
                    <v-icon class='typeIcon' small left color="blue">
                        mdi-file-document
                    </v-icon>
                </template>
                <template v-else-if="documentType=='image'">
                    <v-icon class='typeIcon' small left color="orange">
                        mdi-file-image
                    </v-icon>
                </template>
                <template v-else-if="documentType=='excel'">
                    <v-icon class='typeIcon' small left color="green">
                        mdi-file-chart
                    </v-icon>
                </template>
                <template v-else-if="documentType=='ppt'">
                    <v-icon class='typeIcon' small color="red" style='margin-left:-5px; margin-right:5px;'>
                        mdi-file-powerpoint-outline
                    </v-icon>
                </template>
                <template v-else-if="documentType=='link'">
                    <v-icon class='typeIcon' small color="teal">
                        {{resource.link.icon}}
                    </v-icon>
                </template>
                <template v-else>
                    <v-icon class='typeIcon' small left>
                        mdi-file
                    </v-icon>
                </template>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <p class='resourceLabel' v-bind="attrs" v-on="on">{{resource.label}}</p>
                    </template>
                    <span>{{resource.label}}</span>
                </v-tooltip>

                <v-btn v-if="documentType=='link'" class='downloadOrLinkButton' icon>
                    <v-icon>mdi-link</v-icon>
                </v-btn>
                <v-btn v-else class='downloadOrLinkButton' style='margin: 8px;' color="rgb(122, 24, 34)" icon>
                    <v-icon>mdi-download-circle</v-icon>
                </v-btn>
            </v-chip>
        </template>

    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: false,
            default: 'chip'
        },
        resource: {
            type: Object,
            required: true
        }
    },
    data: function(){
        return {
            url: null
        }
    },
    methods: {
        getSignedUrl: async function(){
            if(!this.url){
                let asset = this.resource.asset
                let response = await this.sendRequest('put','/api/assets/signedUrl', asset)
                if(response.status==200){
                    this.url = response.data.url
                }
            }
            window.open(this.url, '_blank');
            this.processClickEvent()
        },
        processClickEvent: async function(){
            let action = this.documentType=='link' ? 'link_accessed' : 'document_downloaded_viewed'
            let resource = this.documentType=='link' ? this.resource.link : this.resource.asset.Key
            this.sendRequest('post','/api/meetings/assets/usage', {resource: resource, user_id: this.$store.getters.user.username, action: action})
        }
    },
    computed: {
        fileExtension: function(){
            let asset = this.resource.asset
            return asset ? asset.Key.substr(asset.Key.lastIndexOf('.')+1,asset.Key.length).toLowerCase() : null
        },
        documentType: function(){
            let fileExtension = this.fileExtension

            if(['pdf'].includes(fileExtension)){
                return 'pdf'
            }

            if(['ppt','pptx'].includes(fileExtension)){
                return 'ppt'
            }

            if(['doc','docx'].includes(fileExtension)){
                return 'word'
            }

            if(['gif','jpg','jpeg','bmp','png'].includes(fileExtension)){
                return 'image'
            }

            if(['xls','xlsx'].includes(fileExtension)){
                return 'excel'
            }

            if(this.resource.link){
                return 'link'
            }

            return null
        }
    }
}
</script>

<style lang='scss' scoped>

#DocumentCard {

    .resourceChip {
        position: relative;
        border-radius: 2px;
        background-color: white !important;
        box-shadow: 0px 0px 4px 0.5px rgb(0 0 0 / 20%);
        padding: 24px 64px 24px 24px;
        width: 100%;
        min-width: 240px;
        max-width: 240px;

        @media(max-width: 575px){ // xs < 576
            max-width: initial;
        }

        .typeIcon {

            &:before {
                transform: scale(1.5);
                margin-right: 8px;
            }
        }

        .resourceLabel {
            width: 90%;
            overflow-x: scroll;
            overflow-y: hidden;
            position: relative;
            top: 8px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .downloadOrLinkButton {
            position: absolute;
            right: 8px;
        }
    }
}
</style>
